* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

html, body {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
}

body {
  background-color: #f0f0f2;
}

html {
  scroll-behavior: smooth;
}

#root {
  height: 100%;
  width: 100%;
  max-width: 100vw;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset;
}

/* Cor do texto do autocomplete */
input:-webkit-autofill {
  -webkit-text-fill-color: #333 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(176, 176, 177) #f4f4f400;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f4f4f400;
}

*::-webkit-scrollbar-track {
  background-color: #f4f4f400;
}

*::-webkit-scrollbar-thumb {
  background: rgb(176, 176, 177);
  border-radius: 8px;
}

/*EDITOR DE TEXTO*/
.ql-container {
  border-bottom-left-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
  background: #FFF;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #f2f2f2;
  border-top-left-radius: 0.4em;
  border-top-right-radius: 0.4em;
}

/* Bubble Theme */
.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.4em;
}

.ql-editor {
  min-height: 8em;
}

.large .ql-editor {
  min-height: 18em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

/*LIMITAR QUANTIDADE DE TEXTO*/
.delimiter-text-3-row {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


/*TABLE*/
thead tr th {
  vertical-align: top !important;
}


/* REACT TOAST */
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  /* Used only for colored theme */
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided
  toast("**hello**") */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

/* Box Shadow */
.base-box-shadow {
  box-shadow: 0px 5px 5px -5px rgb(0 0 0 / 20%) !important;
}
.base-box-shadow-medium {
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.342) !important;
}
